import React, { useRef } from 'react';
import { ActionManager } from '@babylonjs/core/Actions/actionManager';
import { ExecuteCodeAction } from '@babylonjs/core/Actions';
import { Vector3 } from '@babylonjs/core/Maths/math';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import BabylonScene from 'babylonjs-hook';
import '../layout.css';
import {GlowLayer} from "@babylonjs/core";

import {InterpolateValueAction, StandardMaterial} from "@babylonjs/core";
import {Color3} from "@babylonjs/core/Maths/math";
import {PointLight} from "@babylonjs/core";

import {ArcRotateCamera} from "@babylonjs/core";
import {Link} from "gatsby";

let box;
let babylonLink;

const onSceneReady = scene => {

    //scene.clearColor = new Color3(0.5, 0.8, 0.5);
    // scene.clearColor = new Color3(1, 1, 1); // WAS THIS ONE
    scene.clearColor = new Color3(0.0234375,0.0234375,0.0234375); // CHECK



    // This creates and positions a free camera (non-mesh)
    //var camera = new FreeCamera("camera1", new Vector3(0, 10, -10), scene);
    // This targets the camera to scene origin
    //camera.setTarget(Vector3.Zero());

    // Create a camera which rotates around the object
    var camera = new ArcRotateCamera("camera", 0, Math.PI/2, 10, Vector3.Zero(), scene);

    scene.collisionsEnabled = true;
    //Then apply collisions and gravity to the active camera
    camera.checkCollisions = true;
    camera.lowerRadiusLimit = 7;
    camera.upperRadiusLimit = 22;
    const canvas = scene.getEngine().getRenderingCanvas();

    // This attaches the camera to the canvas
    camera.attachControl(canvas, true);

    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    var light = new PointLight("light", new Vector3(0, 3, 0), scene);
    var light1 = new PointLight("light", new Vector3(3, 0, 0), scene);

    light.intensity = 1;
    light1.intensity = 0.5;

    box = MeshBuilder.CreateBox("box", {size: 2}, scene);
    box.position.y = -1.25;

    // Uploading mesh from server
    //meshme = SceneLoader.ImportMesh()

    // Creating main material
    var boxMaterial = new StandardMaterial("material", scene);
    //boxMaterial.emissiveColor = new Color3(0, 0.58, 0.86);
    boxMaterial.emissiveColor = new Color3(0.15, 0.15, 0.86);

    var boxMaterial1 = new StandardMaterial("material1", scene);
    boxMaterial1.emissiveColor = new Color3(0, 0.58, 0.86);

    // Assigning material
    box.material = boxMaterial;

    var gl = new GlowLayer("glow", scene);

    // Register click event on box mesh
    box.actionManager = new ActionManager(scene);
    box.actionManager.registerAction(
        new ExecuteCodeAction(
            ActionManager.OnPickTrigger,
            () => {
                babylonLink.current.click()
            }
        )
    );


    box.actionManager = new ActionManager(scene)
    box.actionManager.registerAction(
        new InterpolateValueAction(
            ActionManager.OnPointerOverTrigger,
            box.material,
            'alpha',
            0.1,
            250
        )
    )
    box.actionManager.registerAction(
        new InterpolateValueAction(
            ActionManager.OnPointerOutTrigger,
            box.material,
            'alpha',
            1,
            250
        )
    )

    // Our built-in 'ground' shape.
    //MeshBuilder.CreateGround("ground", {width: 6, height: 6}, scene);
}

/**
 * Will run on every frame render.  We are spinning the box on y-axis.
 */
const onRender = scene => {
    if (box !== undefined) {
        var deltaTimeInMillis = scene.getEngine().getDeltaTime();

        const rpm = 30;
        box.rotation.y += ((rpm / 80) * Math.PI * 2 * (deltaTimeInMillis / 1000));
        box.rotation.z += ((rpm / 100) * Math.PI * 2 * (deltaTimeInMillis / 1000));

    }
}

export default () => {
    babylonLink = useRef(null);

    return (
        <>
            <Link to="/">
                <BabylonScene antialias onSceneReady={onSceneReady} onRender={onRender} id='render-canvas' />
            </Link>

            {/*<a ref={babylonLink} target="_self" rel="noopener noreferrer" href="https://pavli.netlify.app/">*/}
            {/*    <BabylonScene antialias onSceneReady={onSceneReady} onRender={onRender} id='render-canvas' />*/}

            {/*</a>*/}
        </>
    )
}
