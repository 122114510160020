import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Image from "../../components/image"
import SEO from "../../components/seo"
import { rhythm } from '../../utils/typography'
import Scene from "../../components/scenes/scene";
import ProfilePic from "../../components/cropped-profile"



const IndexPage = () => (
    <Layout>
        <SEO title="Pavel" />
        <br />
        <br />
        <h1 style={{textAlign:"center",margin:"0 auto" ,marginTop:"0",marginBottom:"1.45rem"}}>
            Servus, willkommen zur Web-Party
        </h1>
        <br />
        <br />
        <p>
            Hier konnen Sie mich besser erkennen<br/>
            You can find my <Link to="/resume/">Resume</Link> here.<br />
            <p/>
            All the projects I have done before and after creation of this website are supposed to appear <Link to="/projects/">here</Link>.
            <br/>
            I can miss some of them, so just <a href="mailto:pavel.popov@mail.polimi.it">contact me</a>, if you have any
            question.
            <br />
            This Website I created with React, you can see the tech stack <a href="/projects/2021-04-01-this-website/"
                                                                             target="_blank" rel="noopener noreferrer">here</a>.
            <br />
            <br />
            <br />
            <div style={{ maxWidth: `300px`, margin: `0 auto` }}>
                <ProfilePic />
            </div>
            {/*<div style={{ maxWidth: `1000px`, margin: `0 auto` }}>*/}
            {/*    <Scene />*/}
            {/*</div>*/}

            <br />
            <br />
            <br />

            <h2>Where I am now</h2>
            I work with simulation modeling, develop secure software and do parametric design.<br />
            Currently I can be somewhere in Germany, Italy or Russia working or travelling in the mountains.
            <br />
            <a href="mailto:pavel.popov@mail.polimi.it">Contact me</a> if you have an interesting project, job proposal
            or maybe you can help to finish OpenCV plugin
            for <a
            href="https://www.food4rhino.com/"
            target="_blank" rel="noopener noreferrer"> Food4Rhino</a>.


            <br />
            <br />
            In the meanwhile, I play CTFs or involved in some CG projects as a freelancer.
            <br /></p>
        <br />

        <div style={{ maxWidth: `1000px`, margin: `0 auto` }}>
            <Scene />
        </div>
    </Layout>

)

export default IndexPage